import * as APIService from "../utils/api-service";
import env from "../utils/environment";
import mondaySDK from "monday-sdk-js";
export default class MondayService {
  static appId = "";
  static clientId = "";

  private static initMonday() {
    return mondaySDK({
      clientId: this.clientId,
      apiVersion: env.REACT_APP_API_VERSION,
    });
  }

  static async getMondayContext() {
    const monday = this.initMonday();
    monday.execute("valueCreatedForUser");
    const res = await monday?.get("context");

    return res?.data;
  }

  static showPlan() {
    const monday = this.initMonday();
    monday.execute("openPlanSelection", {
      isInPlanSelection: true,
      selectedPlanId: "123456",
    });
  }

  static async getLinkOC(accountId: any) {
    try {
      const data = {
        accountId,
        appId: this.appId,
      };
      const rs = await APIService.post(
        `${env.REACT_APP_BACK_END_END_POINT}/bb/get-link-oc`,
        data
      );
      return rs?.data;
    } catch (error) {
      // console.log(error);
    }
  }
  static getLinkUserGuide() {
    switch (this.appId) {
      case '10032082':
        return env.REACT_APP_AIN_GUIDE_URL;
      case '10032081':
        return env.REACT_APP_AGN_GUIDE_URL;
      case '10032083':
        return env.REACT_APP_TTD_GUIDE_URL;
      case '10041563':
        return env.REACT_APP_CPM_GUIDE_URL;
      case '10051758':
        return env.REACT_APP_TAG_GUIDE_URL;
      case '10050993':
        return env.REACT_APP_APR_GUIDE_URL;
      case '10055804':
        return env.REACT_APP_DDGA_GUIDE_URL;
      case '10141318':
        return env.REACT_APP_FVP_GUIDE_URL;
      case '10047973':
        return env.REACT_APP_SPT_GUIDE_URL;
      case '10138999':
        return env.REACT_APP_TOOL_GUIDE_URL;
    }
  }
}
