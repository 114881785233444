import React, { createContext, useEffect, useState } from 'react';
import MondayService from '../utils/monday-service';

const AppContext = createContext({});

const AppProvider = ({ children }) => {
	const [appContext, setAppContext] = useState({});

	useEffect(() => {
		const fetchData = async () => {
			const pathNames = window.location.pathname?.split('/');
			MondayService.appId = pathNames?.[1];
			MondayService.clientId = pathNames?.[2];
			const mondayContext = await MondayService.getMondayContext();
			const linkOC = mondayContext?.account?.id && MondayService.appId && MondayService.clientId ? await MondayService.getLinkOC(mondayContext.account.id) : null;
			const linkUserGuide = MondayService.appId ? await MondayService.getLinkUserGuide() : null;
			setAppContext({
				mondayContext,
				linkOC,
				linkUserGuide,
				appId: MondayService.appId,
				clientId: MondayService.clientId,
			});
		};

		fetchData();
	}, []); // Nếu có các dependency khác liên quan đến mondayContext, thêm vào đây.

	return <AppContext.Provider value={appContext}>{children}</AppContext.Provider>;
};

export { AppContext, AppProvider };
