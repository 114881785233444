import { Heading, Text } from 'monday-ui-react-core';

const PermissionDenied: React.FC = () => {
	return (
		<div className='mt-5'>
			<Text
				type={Text.types.TEXT2}
				className='text-3xl text-center text-red-600'>
				Access Denied
			</Text>
			{/* <div className="divide-x"></div> */}
			<hr className='w-1/2 mx-auto mt-4 text-center'></hr>
			<Text
				type={Text.types.TEXT2}
				className='mt-10 text-xl text-center'>
				As a <span className='text-semibold'>viewer</span>, you don't have permission to view this site.
			</Text>
		</div>
	);
};

export default PermissionDenied;
