import { useContext, useEffect, useState } from 'react';
import { Tab, TabList, ThemeProvider } from 'monday-ui-react-core';
import UserGuideForm from '../components/user-guide';
import BillingForm from '../components/billing';
import OperationCounterForm from '../components/operation-counter';
import { AppContext } from '../context/app-context';
import Intercom from '@intercom/messenger-js-sdk';
import PermissionDenied from '../components/permission-denied';
import MondayService from '../utils/monday-service';
import mondaySDK from 'monday-sdk-js';
import env from '../utils/environment';
import CookieBanner from '../components/cookie-banner';

export default function DashboardPage() {
	const [currentTab, setCurrentTab] = useState(0);
	const appContext = useContext(AppContext);
	const [context, setContext] = useState<any>(null);
	const monday = mondaySDK({
		clientId: (appContext as any)?.clientId || '',
		apiVersion: env.REACT_APP_API_VERSION,
	});
	const isLimitedApp = ['10032083', '10032081', '10032082'].includes((appContext as any)?.appId);

	// Khởi tạo Intercom 1 lần khi component mount
	// useEffect(() => {
	// 	Intercom({
	// 		app_id: 'efnew8bu',
	// 		alignment: 'left',
	// 		horizontal_padding: 100,
	// 		vertical_padding: 40,
	// 	});
	// }, []);

	// Lấy context ban đầu
	useEffect(() => {
		async function fetchData() {
			const tmpContext = await MondayService.getMondayContext();
			setContext(tmpContext);
		}
		fetchData();
	}, []);

	// Lắng nghe thay đổi context từ Monday SDK để cập nhật theme và các cấu hình khác
	useEffect(() => {
		const unsubscribe = monday.listen('context', (res) => {
			setContext(res.data);
		});
		return () => {
			if (unsubscribe) {
				unsubscribe();
			}
		};
	}, []);

	return (
		<>
			<ThemeProvider
				systemTheme={context?.theme}
				themeConfig={context?.themeConfig}>
				{context?.user?.isViewOnly ? (
					<PermissionDenied />
				) : (
					<main className='w-4/5 mx-auto mt-8'>
						<div className='w-4/5 mx-auto'>
							<TabList
								tabType='stretched'
								onTabChange={(tabId) => setCurrentTab(tabId)}>
								<Tab>User Guide</Tab>
								{!isLimitedApp && <Tab>Operation Counter</Tab>}
								<Tab>Billing</Tab>
							</TabList>
						</div>
						<div className='mt-6'>
							{currentTab === 0 && <UserGuideForm />}
							{currentTab === 1 && (!isLimitedApp ? <OperationCounterForm /> : <BillingForm />)}
							{currentTab === 2 && <BillingForm />}
						</div>
					</main>
				)}
			</ThemeProvider>
		</>
	);
}
